import buttonStyles from '@/styles/Buttons.module.scss'
import classNames from 'classnames'
import { MouseEventHandler, ReactNode } from 'react'

interface Props {
  className?: string
  disabled?: boolean
  onClick: MouseEventHandler<HTMLButtonElement>
  children: ReactNode
}

export default function WaitlistEntryModalCompleteBtn({
  className = buttonStyles.greenBtn,
  disabled,
  onClick,
  children,
}: Props) {
  return (
    <button
      id="completeBtn"
      className={classNames(
        buttonStyles.cardBtn,
        'btn mt-md-0 w-50 p-2',
        className,
        disabled ? buttonStyles.disabledBtn : null
      )}
      style={{ fontSize: '0.9rem' }}
      onClick={onClick}
      type="button"
      disabled={disabled}
    >
      {children}
    </button>
  )
}
