import axios from 'axios'
import { Waitlist, PublicWaitlist } from '../interface/waitlist'
import { Media } from '../interface/media'

interface WaitlistParams {
  // no params yet
  waitlistId?: string
}

const parseSettings = (settings: string | object) =>
  typeof settings === "string" ? JSON.parse(settings) : settings

const stringifySettings = (settings: string | object) =>
  typeof settings === "string" ? settings : JSON.stringify(settings)

// get all waitlists
export const getWaitlists = async (): Promise<Array<Waitlist>> => {
  // add params if any
  return axios.get('v2/wait-lists'/*, { params: { include: ["media"] } }*/)
  .then((res) => {
    return res.data.data.map((wl) => ({
      ...wl,
      settings: parseSettings(wl.settings),
    }))
  })
}

// get a single public waitlist
export const getPublicWaitlist = async (waitlistId: Waitlist['id']): Promise<PublicWaitlist> => {
  return axios.get(`v2/public/wait-lists/${waitlistId}`/*, { params: { include: ["media"] } }*/)
    .then((res) => {
      return {
        ...res.data.data,
        settings: parseSettings(res.data.data.settings),
      }
    })
  }

// create
export const createWaitlist = async (waitlist: Partial<Waitlist>): Promise<Waitlist> => {
  return axios.post('v2/wait-lists', { ...waitlist, settings: stringifySettings(waitlist.settings) })
    .then((res) => {
      return {
        ...res.data.data,
        settings: parseSettings(res.data.data.settings),
      }
    })
}

// update
export const updateWaitlist = async (waitlist: Partial<Waitlist>): Promise<Waitlist> => {
  const { id } = waitlist
  return axios.put(`v2/wait-lists/${id}`, { ...waitlist, settings: stringifySettings(waitlist.settings) })
    .then((res) => {
      return {
        ...res.data.data,
        settings: parseSettings(res.data.data.settings),
      }
    })
}

// restore
export const restoreWaitlist = async (waitlist: Partial<Waitlist>): Promise<Waitlist> => {
  const { id } = waitlist
  return axios.patch(`v2/wait-lists/${id}/restore`, waitlist)
    .then((res) => {
      return {
        ...res.data.data,
        settings: parseSettings(res.data.data.settings),
      }
    })
}

// delete

// media
export const createWaitlistMedia = (
  waitlistId: Waitlist["id"],
  media: FormData,
): Promise<Media> => 
axios
    .post(`v2/wait-lists/${waitlistId}/media`, media, {
      headers: { "Content-Type": "multipart/form-data" }
  })
  .then((res) => res.data.data)
