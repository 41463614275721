import useSWR from "swr"
import { refreshIntervals } from "@/consts/swr"
import { getQueues } from "@/api/nextme/queues"
import { useEffect } from "react"

export default function useQueues(withTrashed?: boolean) {
  
  const {
    data: queues,
    isValidating,
    mutate: mutateQueues,
    error,
  } = useSWR(`/nextme/queues/${withTrashed ? 'with_trashed' : null}`, () => getQueues(withTrashed), {
    fallbackData: [],
    refreshInterval: refreshIntervals.verySlow,
    dedupingInterval: 10000, // 10s
    revalidateOnFocus: false,
    refreshWhenHidden: true,
  })

  return {
    isValidating,
    queues,
    mutateQueues,
    error,
  }
}
