/** @type {import('tailwindcss').Config} */
export default {
  content: [
    './src/**/*.{js,ts,jsx,tsx}',
    './src/components/**/*.{js,ts,jsx,tsx,scss}',
  ],
  theme: {
    colors: {
      black: '#000',
      midnight: '#051336',
      twilight: '#202b7e',
      blue: '#005bff',
      green: '#25c955',
      yellow: '#ffc165',
      red: '#e85757',
      slate: '#1d1d1d',
      gray: {
        DEFAULT: '#757575',
        dark: '#757575',
        light: '#ececec',
      },
      white: '#ffffff',
      warning: '#E85757',
      info: '#202b7e',
    },
    extend: {
      screens: {
        xs: '376px',
      },
    },
  },
  plugins: [],
}
